// url,id,token
import { ElNotification}  from 'element-plus'
import emitter from '@/utils/bus.js';
export function websocket(url, id, token) {
    if (typeof (WebSocket) === "undefined") {
        console.log("您的浏览器不支持 WebSocket!");
    }
    else {
        let ws = new WebSocket(process.env.VUE_APP_WEBSOCKET_API + url + id, [token]);
        // let ws = new WebSocket('ws://192.168.31.206:9898' + url + id, [token]);
        ws.onopen = function (msg) {
            // Web Socket 已连接上，使用 send() 方法发送数据
            ws.send("发送数据")
            console.log("发送数据", msg)
        };
        ws.onmessage = function (evt) {
            console.log("接收数据", evt);
            emitter.emit('new', 'ok');
            // 处理推送过来的消息
            let message = JSON.parse(JSON.parse(evt.data).content)
            // 充值成功
            if (message.subType == 'pay') {
                ElNotification({
                    title: '您有新消息',
                    message: message.text,
                    type: 'success'
                });
                emitter.emit('getTarget', 'ok');
            }
            // 普通消息
            if (message.subType == 'default') {
                ElNotification({
                    title: '您有新消息',
                    message: message.text,
                    type: 'success'
                });
                if (message.text == "认证成功") {
                    emitter.emit('results', 'ok');
                }
            }
            // 导出
            if (message.subType == 'download') {
                ElNotification({
                    title: '您有新消息',
                    message: message.text,
                    type: 'success'
                });
            }
            // 导入
            if (message.subType == 'import') {
                ElNotification({
                    title: '您有新消息',
                    message: JSON.parse(JSON.parse(received_msg).content).text,
                    type: 'success'
                });
            }
        };
        ws.onclose = function () {
            // 关闭 websocket
            console.log("websocket关闭")
        };
    }
}
